<template>
  <div :key="$route.params.id">
    <div v-if="!loaded">
      <flixLoader />
    </div>
    <div v-else>
      <div v-if="$route.params.id === 'new'">
        <appointmentAssistent :services="services" :data="{user: $store.getters.user.md5_id}"/>
      </div>
      <div v-else-if="$route.params.id !== 'new'">
        <div v-if="data">
          <div class="flix-form-group" style="margin-bottom: 50px;">
            <appointmentAssistent :data="data" :services="services" />
          </div>
          <div class="flix-form-group flix-text-right">
            <router-link :to="{name: 'dashboardAssistentDelete', params: {id: $route.params.id}}" class="flix-html-a flix-text-danger" >
              <flixIcon id="bin" /> {{ data.title }} {{ $t('message.delete') }}
            </router-link>
          </div>
          <div class="flix-form-group flix-text-right" v-if="!$store.getters.assistents.length || $store.getters.assistents.length < variables.user.max_calendar">
            <div v-if="clone" class="flix-text-center">
              <flixLoader />
              {{ $t('message.duplicate') }}
            </div>
            <a v-else href="#" class="flix-html-a" @click.prevent="setClone">
              <flixIcon id="copy" /> {{ $t('message.createNew', {name: data.title}) }} / {{ $t('message.duplicate') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    appointmentAssistent () { return import('@/components/assistent/') }
  },
  props: {},
  data () {
    return {
      assistents: [],
      services: [],
      data: false,
      loaded: false,
      variables: this.$getUserVariables(),
      clone: false
    }
  },
  methods: {
    setClone () {
      this.clone = true
      this.$store.dispatch('setCloneAssistent',
        {
          ID: this.data.ID,
          callback: function (data) {
            this.$store.dispatch('setAssistent', {
              ID: data.ID,
              data: data,
              callback: function () {
                this.$store.dispatch('setStandardReminder', {
                  ID: data.ID,
                  callback: function () {
                    var assistents = this.$store.getters.assistents
                    assistents.push(data)
                    this.$store.commit('setAssistents', assistents)
                    if (typeof data.styling !== 'undefined' && typeof data.styling.accent !== 'undefined') {
                      this.$store.commit('setAssistentColor', { ID: data.ID, color: data.styling.accent })
                      this.$store.commit('setActiveAssistent', { ID: data.ID, color: data.styling.accent, title: data.title, date: false })
                    }
                    setTimeout(function () {
                      this.$router.push({ name: 'dashboardAssistentEdit', params: { id: data.ID } })
                      this.loaded = false
                      this.setLoad()
                      this.clone = false
                    }.bind(this), 1000)
                  }.bind(this)
                })
              }.bind(this)
            })
          }.bind(this)
        })
    },
    getOpen (open, assistentID) {
      if (open === assistentID) {
        this.open = false
      } else {
        this.open = assistentID
      }
    },
    getData (ID) {
      this.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: this.$store.getters.user.md5_id,
          ID: ID
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.data = ret.data[1]
            this.data.ID = ID
          }
          // console.log(ret.data)
          this.loaded = true
        }.bind(this)
      })
    },
    getServices (callback) {
      this.$flix_post({
        url: 'assistent/get_services',
        data: {
          user: this.$store.getters.user.md5_id
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.services = ret.data[1]
          }
          callback()
        }.bind(this)
      })
    },
    setLoad () {
      this.getServices(function () {
        this.getData(this.$route.params.id)
      }.bind(this))
    }
  },
  mounted () {
    this.setLoad()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-panel
    .flix-panel-body, .flix-panel-heading
      border: 1px solid #ccc
</style>
